import { createRoot } from "react-dom/client";
import { jsonParse } from "../../../../wp-utils/v1";
import Viewer from "./Components/Viewer";
import { useRef } from "react";

import "./../../public/style.scss";
import { findParentAnchorTag } from "../../utils";

const FrontEnd = ({ attributes }) => {
  function __(text, textdomain = "") {
    return text;
  }
  const viewerRef = useRef();

  if (attributes) {
    return <Viewer attributes={attributes} __={__} viewerRef={viewerRef} />;
  }
};

export default FrontEnd;

document.addEventListener("DOMContentLoaded", function () {
  const blocks = document.querySelectorAll(".modelViewerBlock");
  if (window.domRendered) {
    return;
  }
  // return;
  window.domRendered = true;
  blocks.forEach((dom) => {
    const dataset = { ...dom.dataset } || {};
    setTimeout(() => {
      Object.keys(dom.dataset).map((key) => delete dom.dataset[key]);
    }, 10);

    const attributes = jsonParse(dataset.attributes);
    if (attributes) {
      const selector = dataset.selector;
      const position = dataset.position;
      const unique_class = dataset.uniqueClass;
      const type = dataset.type;
      const { isPagination, styles } = attributes;

      if (selector == "") {
        return;
      }

      const div = document.createElement("div");
      if (selector && document.querySelector(selector)) {
        Object.keys(dom.dataset).map((key) => delete dom.dataset[key]);
        dom = document.querySelector(selector);
      }
      // return false;
      if (!attributes?.currentViewer || attributes.currentViewer === "modelViewer") {
        dom.setAttribute("data-rendered", "true");
        div.classList.add("woocommerce-product-model-viewer-wrapper");
        div.classList.add(`position_${position}`);
        dom.classList.add(`product_${unique_class}`);
        renderModel({ div, dom, position, styles, isPagination, attributes, type });
        const Src = document.getElementById("bp3d-model-viewer-js");
        if (!Src) {
          const script = document.createElement("script");
          script.type = "module";
          script.id = "bp3d-model-viewer-js";
          script.src = window["bp3dBlock"]?.modelViewerSrc;
          document.head.appendChild(script);
        }
      }
      if (attributes.currentViewer === "O3DViewer") {
        const Src = document.getElementById("bp3d-o3dviewer-js");
        if (!Src) {
          const script = document.createElement("script");
          script.id = "bp3d-o3dviewer-js";
          script.src = window["bp3dBlock"]?.o3dviewerSrc;
          document.head.appendChild(script);
          script.addEventListener("load", () => {
            renderModel({ div, dom, position, styles, isPagination, attributes, type });
            // const root = createRoot(dom);
            // root.render(<FrontEnd attributes={attributes} />, dom);
          });
        } else {
          Src.addEventListener("load", () => {
            renderModel({ div, dom, position, styles, isPagination, attributes, type });
            // const root = createRoot(dom);
            // root.render(<FrontEnd attributes={attributes} />, dom);
          });
        }
      }
    }
  });
});

export const renderModel = ({ div, dom, position, styles, attributes, isPagination, type }) => {
  if (position === "bottom") {
    setTimeout(() => {
      dom.appendChild(div);
      dom = div;
      dom.setAttribute("style", `width:${dom.offsetWidth > 0 ? dom.offsetWidth + "px" : "100%"};height:calc(${styles.height} + ${isPagination ? "100px" : "0px"})`);
      const root = createRoot(dom);
      root.render(<FrontEnd attributes={attributes} />, dom);
    }, 100);
  } else if (position === "top") {
    dom.insertBefore(div, dom.firstChild);
    dom = div;
    setTimeout(() => {
      dom.setAttribute("style", `width:${dom.offsetWidth > 0 ? dom.offsetWidth + "px" : "100%"};height:calc(${styles.height} + ${isPagination ? "100px" : "0px"})`);
      const root = createRoot(dom);
      root.render(<FrontEnd attributes={attributes} />, dom);
    }, 100);
  } else {
    dom.setAttribute("style", `width:${dom.offsetWidth > 0 ? dom.offsetWidth + "px" : "100%"};height:calc(${styles.height} + ${isPagination && type !== "popup" ? "100px" : "0px"})`);
    const root = createRoot(dom);
    root.render(<FrontEnd attributes={attributes} />, dom);
  }

  // set height of the model like other product
  if (dom.classList.contains("productListItem")) {
    const height = dom.nextElementSibling?.offsetHeight;
    if (height) {
      dom.style.height = height + "px";
      dom.nextElementSibling.style.display = "none";
      const interval = setTimeout(() => {
        if (dom.querySelector(".modelViewerBlock")) {
          clearInterval(interval);
          dom.querySelector(".modelViewerBlock").style.height = height + "px";
          dom.querySelector(".bp_model_parent").setAttribute("style", `height:${height + "px"};min-width: 100%`);
          const anchorTag = findParentAnchorTag(dom);

          anchorTag.addEventListener("click", function (e) {
            if (e.target.id === "openBtn" || e.target.id === "closeBtn" || e.target.tagName === "MODEL-VIEWER") {
              e.preventDefault();
            }
          });
        }
      }, 500);
    }
  }
};
