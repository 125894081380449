export function getParams(url = "") {
  url = url || location?.href;
  var queryStart = url.indexOf("?") + 1,
    queryEnd = url.indexOf("#") + 1 || url.length + 1,
    query = url.slice(queryStart, queryEnd - 1),
    pairs = query.replace(/\+/g, " ").split("&"),
    parms = {},
    i,
    n,
    v,
    nv;

  if (query === url || query === "") return;

  for (i = 0; i < pairs.length; i++) {
    nv = pairs[i].split("=", 2);
    n = decodeURIComponent(nv[0]);
    v = decodeURIComponent(nv[1]);

    if (!parms.hasOwnProperty(n)) parms[n] = [];
    parms[n] = nv.length === 2 ? v : null;
  }
  return parms;
}

export function restoreOriginalImageSrc(imageSrc) {
  // Define a regular expression pattern to match sizes like "-100x100", "-200x200", etc.
  const sizePattern = /-\d{2,4}x\d{2,4}/g;

  // Remove any size string from the image src and the hyphen before it
  const originalSrc = imageSrc.replace(sizePattern, "");

  return originalSrc;
}

export function isImageSource(str) {
  // Regular expression to match common image file extensions
  const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i;

  // Check if the string matches the image extension pattern
  return imageExtensions.test(str);
}

export function findParentUntilMultipleChildren(element) {
  let parent = element.parentElement;

  while (parent && parent.children.length === 1) {
    element = parent;
    parent = parent.parentElement;
  }

  return element;
}

export function findParentAnchorTag(element) {
  let parent = element.parentElement;

  while (parent && parent.tagName === "A") {
    element = parent;
    parent = parent.parentElement;
  }

  return element;
}

export function getMaterials(materials) {
  if (Array.isArray(materials)) {
    return materials.map((item) => item.name);
  }
  return [];
}

export function getMaterialsNameForSelectControl(materials = []) {
  if (Array.isArray(materials)) {
    return materials.map((item) => {
      const label = item.name.replace("_mtl", "").replaceAll("_", " ");
      return { label, value: item.name };
    });
  }
  return [];
}

export function rgbaToHex(rgba) {
  // Extract the RGBA values from the string
  const rgbaValues = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([0-9.]+)?\)/);

  if (!rgbaValues) {
    throw new Error("Invalid RGBA color string");
  }

  // Parse the R, G, B values
  const r = parseInt(rgbaValues[1]);
  const g = parseInt(rgbaValues[2]);
  const b = parseInt(rgbaValues[3]);

  // Parse the alpha value, default to 1 if not provided
  const a = rgbaValues[4] !== undefined ? parseFloat(rgbaValues[4]) : 1;

  // Convert to hex
  const toHex = (n) => n.toString(16).padStart(2, "0").toUpperCase();

  // Convert alpha to hex (if needed)
  const alphaHex = toHex(Math.round(a * 255));

  // Return hex with or without alpha
  return `#${toHex(r)}${toHex(g)}${toHex(b)}${a < 1 ? alphaHex : ""}`;
}
