import "./style.scss";
// import "./index.js";

window.addEventListener("elementor/frontend/init", function () {
  elementorFrontend.hooks.addAction("frontend/element_ready/3dModelViewer.default", function (scope, $) {
    const wrapper = scope[0].querySelector(".b3dviewer-wrapper.elementor");
    modelRenderer({ animation: true, variant: true, wrapper });
  });
});

// Full Screen
document.addEventListener("DOMContentLoaded", function () {
  const modelWrapper = document.querySelectorAll(".modelViewerBlock");

  setTimeout(() => {
    Object.values(modelWrapper).map((wrapper) => {
      const models = wrapper.querySelectorAll("model-viewer") || wrapper.querySelectorAll(".online_3d_viewer");
      const progressElement = wrapper.querySelector(".progress");

      models.forEach((model) => {
        const src = new URL(model?.src || model?.getAttribute("src") || model?.getAttribute("model"));
        if (model.tagName === "DIV") {
          console.log("Advanced viewer");
        } else {
          // add/remove ssl from url depends on website
          if (src?.protocol !== location?.protocol) {
            model?.setAttribute("src", src.href.replace(src?.protocol, location?.protocol));
          }

          if (progressElement) {
            model?.addEventListener("progress", (e) => {
              progressElement.innerHTML = parseInt(e.detail.totalProgress * 100) + "%";
            });
            model?.addEventListener("load", () => {
              setTimeout(() => {
                progressElement.style.display = "none";
              }, 500);
            });
          }

          model.dataset.items = "";

          // set model width for goya theme
          const productModalContainer = document.querySelector(".product-modal-wrap");
          if (
            productModalContainer &&
            !productModalContainer?.nextElementSibling?.classList?.contains("entry-summary") &&
            !productModalContainer?.nextElementSibling?.classList?.contains("mkd-single-product-summary")
          ) {
            // productModalContainer.style.width = "100%";
            productModalContainer.classList.add("model-full-width");
            const information = document.querySelector(".showcase-inner .product-information");
            if (information) {
              const model = productModalContainer.querySelector("model-viewer");
              if (model) {
                model.style.height = information.offsetHeight + "px";
              }
            }
          }
        }
      });
    });
  }, 1000);
});

jQuery(document).ready(function ($) {
  $(".bp3dmodel-carousel")?.[0]?.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  });
});
